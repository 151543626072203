(function() {
    angular.module('common.appversion', [])
        .directive('aflAppVersion', aflAppVersion);

    aflAppVersion.$inject = [];

    function aflAppVersion() {
        return {
            restrict: 'E',
            scope: {
                aerosVersion:'=',
                buildNumber:'='
            },
            templateUrl:'/static/javascript/directives/afl-app-version/afl-app-version.html'
        }
    }
})();
